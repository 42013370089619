import { createMuiTheme } from "@material-ui/core/styles";
import { amber, green } from "@material-ui/core/colors";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#823cd7",
    },
    secondary: {
      main: "#289758",
    },
    error: {
      main: "#E94A4A",
    },
    background: {
      default: "#ffffff",
    },
    warning: {
      main: amber[500],
    },
    success: {
      main: green[700],
    },
  },
  typography: {
    fontSize: 11,
  },
});
export default theme;
//black #535150
//primary dal #CA7DF9
//blue ang chi #6161F3
