import React, { lazy, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  SwipeableDrawer,
  CircularProgress,
  ListItemIcon,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  AccountCircle,
  ExitToApp,
  Category,
  HistoryOutlined,
  Notifications,
} from "@material-ui/icons";
import { logout } from "../actions";
import { Suspense } from "react";
const QuickSale = lazy(() => import("../pages/client/QuickSale"));

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  title: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  drawerDesktop: {
    display: "none",
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
      display: "block",
      width: drawerWidth,
    },
  },

  drawerPaper: {
    width: drawerWidth,
  },

  drawerPaperDesktop: {
    width: drawerWidth,
    zIndex: 1,
    backgroundColor: "transparent",
    border: 0,
  },

  content: {
    flexGrow: 1,
    marginTop: 60,
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
    },
  },
}));

const Navbar = (props) => {
  const { children, decoded, logout, profile } = props;
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);

  const location = useLocation().pathname;

  const renderSideBar = () => {
    //admin
    if (decoded.type === 1) {
      return (
        <React.Fragment>
          <ListItem
            style={{
              backgroundColor: location === "/" && "#fff",
            }}
            button
            dense
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/"
          >
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontWeight: 500,
                    color: location === "/" ? "#222" : "#fff",
                  }}
                >
                  INBOX
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            style={{
              backgroundColor:
                location === "/admin/client-management" && "#fff",
            }}
            button
            dense
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/admin/client-management"
          >
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontWeight: 500,
                    color:
                      location === "/admin/client-management" ? "#222" : "#fff",
                  }}
                >
                  CLIENT MANAGEMENT
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            style={{
              backgroundColor: location === "/admin/plan-management" && "#fff",
            }}
            button
            dense
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/admin/plan-management"
          >
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontWeight: 500,
                    color:
                      location === "/admin/plan-management" ? "#222" : "#fff",
                  }}
                >
                  PLAN MANAGEMENT
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            style={{
              backgroundColor: location === "/admin/bill-management" && "#fff",
            }}
            button
            dense
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/admin/bill-management"
          >
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontWeight: 500,
                    color:
                      location === "/admin/bill-management" ? "#222" : "#fff",
                  }}
                >
                  BILL MANAGEMENT
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            style={{
              backgroundColor:
                location === "/admin/cancelled-invoice" && "#fff",
            }}
            button
            dense
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/admin/cancelled-invoice"
          >
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontWeight: 500,
                    color:
                      location === "/admin/cancelled-invoice" ? "#222" : "#fff",
                  }}
                >
                  CANCELLED INVOICE
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            style={{
              backgroundColor: location === "/admin/admin-management" && "#fff",
            }}
            button
            dense
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/admin/admin-management"
          >
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontWeight: 500,
                    color:
                      location === "/admin/admin-management" ? "#222" : "#fff",
                  }}
                >
                  ADMIN MANAGEMENT
                </Typography>
              }
            />
          </ListItem>
        </React.Fragment>
      );
    }
    //client and employee
    else if (decoded.type > 1) {
      return (
        <React.Fragment>
          <ListItem
            style={{
              backgroundColor: location === "/" && "#fff",
              borderTopLeftRadius: 18,
              borderBottomLeftRadius: 18,
            }}
            button
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to="/"
          >
            <ListItemIcon>
              <img
                style={{ width: 20, height: 20 }}
                alt="Dashboard"
                src={
                  location === "/"
                    ? "/images/dashboard-black.png"
                    : "/images/dashboard-white1.png"
                }
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  color="primary"
                  style={{
                    fontWeight: 500,
                    color: location === "/" ? "#222" : "#fff",
                  }}
                >
                  Dashboard
                </Typography>
              }
            />
          </ListItem>
          {decoded.type === 3 ? (
            <ListItem
              style={{
                backgroundColor:
                  location === "/employee-notification" && "#fff",
                borderTopLeftRadius: 18,
                borderBottomLeftRadius: 18,
              }}
              button
              onClick={() => setOpenDrawer(false)}
              component={Link}
              to="/employee-notification"
            >
              <ListItemIcon>
                {location === "/employee-notification" ? (
                  <Notifications
                    style={{ color: "#535150", width: 20, height: 20 }}
                  />
                ) : (
                  <Notifications
                    style={{ color: "white", width: 20, height: 20 }}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    color="primary"
                    style={{
                      fontWeight: 500,
                      color:
                        location === "/employee-notification" ? "#222" : "#fff",
                    }}
                  >
                    Notifications
                  </Typography>
                }
              />
            </ListItem>
          ) : null}
          {decoded.type === 2
            ? renderStockManagement()
            : profile.role.includes("X") || profile.role.includes("B")
            ? renderStockManagement()
            : null}
          {decoded.type === 2
            ? renderSalesManagement()
            : profile.role.includes("X") || profile.role.includes("A")
            ? renderSalesManagement()
            : null}

          {decoded.type === 2 ? (
            <>
              {profile.plan === 2 ? (
                <>
                  <ListItem
                    style={{
                      backgroundColor:
                        location === "/employee-management" && "#fff",
                      borderTopLeftRadius: 18,
                      borderBottomLeftRadius: 18,
                    }}
                    button
                    onClick={() => setOpenDrawer(false)}
                    component={Link}
                    to="/employee-management"
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: 20, height: 20 }}
                        alt="Employee"
                        src={
                          location === "/employee-management"
                            ? "/images/employee-management-black.png"
                            : "/images/employee-management-white.png"
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          color="primary"
                          style={{
                            fontWeight: 500,
                            color:
                              location === "/employee-management"
                                ? "#222"
                                : "#fff",
                          }}
                        >
                          Employee Management
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    style={{
                      backgroundColor:
                        location === "/attendance-management" && "#fff",
                      borderTopLeftRadius: 18,
                      borderBottomLeftRadius: 18,
                    }}
                    button
                    onClick={() => setOpenDrawer(false)}
                    component={Link}
                    to="/attendance-management"
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: 20, height: 20 }}
                        alt="attendance"
                        src={
                          location === "/attendance-management"
                            ? "/images/attendance-management-black.png"
                            : "/images/attendance-management-white.png"
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          color="primary"
                          style={{
                            fontWeight: 500,
                            color:
                              location === "/attendance-management"
                                ? "#222"
                                : "#fff",
                          }}
                        >
                          Attendance Management
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    style={{
                      backgroundColor:
                        location === "/send-notification" && "#fff",
                      borderTopLeftRadius: 18,
                      borderBottomLeftRadius: 18,
                    }}
                    button
                    onClick={() => setOpenDrawer(false)}
                    component={Link}
                    to="/send-notification"
                  >
                    <ListItemIcon>
                      {location === "/send-notification" ? (
                        <Notifications
                          style={{ color: "#535150", width: 20, height: 20 }}
                        />
                      ) : (
                        <Notifications
                          style={{ color: "white", width: 20, height: 20 }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          color="primary"
                          style={{
                            fontWeight: 500,
                            color:
                              location === "/send-notification"
                                ? "#222"
                                : "#fff",
                          }}
                        >
                          Send Notification
                        </Typography>
                      }
                    />
                  </ListItem>
                </>
              ) : null}
              <ListItem
                style={{
                  backgroundColor: location.includes("/expenses") && "#fff",
                  borderTopLeftRadius: 18,
                  borderBottomLeftRadius: 18,
                }}
                button
                onClick={() => setOpenDrawer(false)}
                component={Link}
                to="/expenses"
              >
                <ListItemIcon>
                  <img
                    style={{ width: 20, height: 20 }}
                    alt="expenses"
                    src={
                      location.includes("/expenses")
                        ? "/images/expenseblack.png"
                        : "/images/expensewhite.png"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color="primary"
                      style={{
                        fontWeight: 500,
                        color: location.includes("/expenses") ? "#222" : "#fff",
                      }}
                    >
                      Expenses
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: location.includes("/sales-report") && "#fff",
                  borderTopLeftRadius: 18,
                  borderBottomLeftRadius: 18,
                }}
                button
                onClick={() => setOpenDrawer(false)}
                component={Link}
                to="/sales-report"
              >
                <ListItemIcon>
                  <img
                    style={{ width: 20, height: 20 }}
                    alt="sales report"
                    src={
                      location.includes("/sales-report")
                        ? "/images/salesreportblack.png"
                        : "/images/salesreportwhite.png"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color="primary"
                      style={{
                        fontWeight: 500,
                        color: location.includes("/sales-report")
                          ? "#222"
                          : "#fff",
                      }}
                    >
                      Sales Report
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: location.includes("/gst-report") && "#fff",
                  borderTopLeftRadius: 18,
                  borderBottomLeftRadius: 18,
                }}
                button
                onClick={() => setOpenDrawer(false)}
                component={Link}
                to="/gst-report"
              >
                <ListItemIcon>
                  <img
                    style={{ width: 20, height: 20 }}
                    alt="gst report"
                    src={
                      location.includes("/gst-report")
                        ? "/images/gstreportblack.png"
                        : "/images/gstreportwhite.png"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color="primary"
                      style={{
                        fontWeight: 500,
                        color: location.includes("/gst-report")
                          ? "#222"
                          : "#fff",
                      }}
                    >
                      GST Report
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: location === "/my-store" && "#fff",
                  borderTopLeftRadius: 18,
                  borderBottomLeftRadius: 18,
                }}
                button
                onClick={() => setOpenDrawer(false)}
                component={Link}
                to="/my-store"
              >
                <ListItemIcon>
                  <img
                    style={{ width: 20, height: 20 }}
                    alt="my-store"
                    src={
                      location === "/my-store"
                        ? "/images/my-store-black.png"
                        : "/images/my-store-white.png"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color="primary"
                      style={{
                        fontWeight: 500,
                        color: location === "/my-store" ? "#222" : "#fff",
                      }}
                    >
                      My Store
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: location === "/support" && "#fff",
                  borderTopLeftRadius: 18,
                  borderBottomLeftRadius: 18,
                }}
                button
                onClick={() => setOpenDrawer(false)}
                component={Link}
                to="/support"
              >
                <ListItemIcon>
                  <img
                    style={{ width: 20, height: 20 }}
                    alt="support"
                    src={
                      location === "/support"
                        ? "/images/support-black.png"
                        : "/images/support-white.png"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color="primary"
                      style={{
                        fontWeight: 500,
                        color: location === "/support" ? "#222" : "#fff",
                      }}
                    >
                      Support
                    </Typography>
                  }
                />
              </ListItem>
            </>
          ) : null}
        </React.Fragment>
      );
    } else {
      return <CircularProgress />;
    }
  };

  const renderStockManagement = () => (
    <ListItem
      style={{
        backgroundColor: location === "/stock-management" && "#fff",
        borderTopLeftRadius: 18,
        borderBottomLeftRadius: 18,
      }}
      button
      onClick={() => setOpenDrawer(false)}
      component={Link}
      to="/stock-management"
    >
      <ListItemIcon>
        <img
          style={{ width: 20, height: 20 }}
          alt="Stock"
          src={
            location === "/stock-management"
              ? "/images/stock-management-black.png"
              : "/images/stock-management-white.png"
          }
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            color="primary"
            style={{
              fontWeight: 500,
              color: location === "/stock-management" ? "#222" : "#fff",
            }}
          >
            Stock Management
          </Typography>
        }
      />
    </ListItem>
  );
  const renderSalesManagement = () => (
    <ListItem
      style={{
        backgroundColor: location.includes("/sales-management") && "#fff",
        borderTopLeftRadius: 18,
        borderBottomLeftRadius: 18,
      }}
      button
      onClick={() => setOpenDrawer(false)}
      component={Link}
      to="/sales-management"
    >
      <ListItemIcon>
        <img
          style={{ width: 20, height: 20 }}
          alt="sales"
          src={
            location.includes("/sales-management")
              ? "/images/sales-management-black.png"
              : "/images/sales-management-white.png"
          }
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            color="primary"
            style={{
              fontWeight: 500,
              color: location.includes("/sales-management") ? "#222" : "#fff",
            }}
          >
            Sales Management
          </Typography>
        }
      />
    </ListItem>
  );

  const renderMenu = () => {
    return (
      <Menu
        id="account-menu"
        style={{ marginTop: -45, marginLeft: 20 }}
        anchorEl={openMenu}
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(false)}
      >
        <MenuItem
          style={{ width: 170, fontWeight: 500 }}
          component={Link}
          to="/account"
          onClick={() => setOpenMenu(false)}
        >
          <AccountCircle style={{ marginRight: 10 }} />
          My Account
        </MenuItem>
        {decoded.type === 2 ? (
          <>
            <MenuItem
              style={{ width: 170, fontWeight: 500 }}
              component={Link}
              to="/payment-history"
              onClick={() => setOpenMenu(false)}
            >
              <HistoryOutlined style={{ marginRight: 10 }} />
              Payment History
            </MenuItem>

            <MenuItem
              style={{ width: 170, fontWeight: 500 }}
              component={Link}
              to="/my-plan"
              onClick={() => setOpenMenu(false)}
            >
              <Category style={{ marginRight: 10 }} />
              My Plan
            </MenuItem>
          </>
        ) : (
          ""
        )}
        <MenuItem
          style={{ width: 170, fontWeight: 500 }}
          onClick={() => {
            setOpenMenu(false);
            logout();
          }}
        >
          <ExitToApp style={{ marginRight: 10 }} />
          Logout
        </MenuItem>
      </Menu>
    );
  };

  const renderQuickSales = () => (
    <Suspense
      fallback={
        <div
          style={{
            marginTop: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      <QuickSale />
    </Suspense>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        style={{
          zIndex: 2,
          boxShadow: "0px 1px 2px #ddd",
          backgroundColor: "#fff",
        }}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="open drawer"
            onClick={(e) => {
              setOpenDrawer(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/" style={{ textDecoration: "none" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className={classes.title}
                alt="Logo"
                src="/logo512.png"
                style={{ height: 30 }}
              />{" "}
              <Typography
                style={{
                  color: "#000",
                  fontWeight: 600,
                  fontSize: 16,
                  marginLeft: 5,
                }}
              >
                STOCKUS
              </Typography>
            </div>
          </Link>
          <div className={classes.grow} />
          {decoded.type > 1 && (
            <Typography
              color="textPrimary"
              style={{
                fontSize: 16,
                fontWeight: 500,
                marginRight: 20,
              }}
            >
              {profile.name}
            </Typography>
          )}
          {decoded.type > 1
            ? decoded.type === 2
              ? renderQuickSales()
              : profile.role.includes("X") || profile.role.includes("A")
              ? renderQuickSales()
              : null
            : null}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} />
        <List
          style={{
            background: "#823cd7",
            height: "100%",
          }}
        >
          {renderSideBar()}
          {renderMenu()}
          <ListItem
            aria-controls="account-menu"
            style={{
              position: "absolute",
              bottom: 20,
              borderTopLeftRadius: 15,
              borderBottomLeftRadius: 15,
            }}
            button
            dense
            onClick={(e) => setOpenMenu(e.currentTarget)}
            // component={Link}
            // to="/account"
          >
            <ListItemIcon>
              <AccountCircle style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: 500, color: "#fff" }}>
                  {profile.name}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </SwipeableDrawer>
      <Drawer
        className={classes.drawerDesktop}
        anchor="left"
        variant="persistent"
        open={true}
        classes={{
          paper: classes.drawerPaperDesktop,
        }}
      >
        <div style={{ height: 56 }} />

        <List
          style={{
            background: "#823cd7",
            height: "100%",
          }}
        >
          {renderSideBar()}
          {renderMenu()}
          <ListItem
            aria-controls="account-menu"
            style={{
              position: "absolute",
              bottom: 20,
              backgroundColor: location === "/account" && "#fff",
              borderTopLeftRadius: 15,
              borderBottomLeftRadius: 15,
            }}
            button
            dense
            onClick={(e) => setOpenMenu(e.currentTarget)}
            // component={Link}
            // to="/account"
          >
            <ListItemIcon>
              <AccountCircle
                style={{ color: location === "/account" ? "#222" : "#fff" }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontWeight: 500,
                    color: location === "/account" ? "#222" : "#fff",
                    backgroundColor: location === "/account" && "#fff",
                  }}
                >
                  {profile.name}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    decoded: state.decoded,
    profile: state.profile,
  };
};
export default connect(mapStateToProps, { logout })(Navbar);
