export default (state = [], action) => {
  switch (action.type) {
    case "FETCH_PRODUCTS":
      return action.payload;
    case "ADD_PRODUCT":
      return [...state, action.payload];
    case "UPDATE_PRODUCT":
      return state.map((s) =>
        s.p_code === action.payload.p_code ? action.payload : s
      );
    case "INCREASE_PRODUCT":
      return state.map((s) =>
        s.p_code === action.payload.p_code
          ? {
              ...action.payload,
              p_quantity:
                parseInt(s.p_quantity) + parseInt(action.payload.p_quantity),
            }
          : s
      );
    case "REMOVE_PRODUCT":
      return state.filter((s) => s.p_code !== action.payload);
    case "CLEAR_PRODUCT":
      return [];
    default:
      return state;
  }
};
