import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import {
  checkAuth,
  fetchPlan,
  fetchDecoded,
  fetchProfile,
  fetchProducts,
  fetchEmployeeNotification,
} from "./actions";
import Navbar from "./components/Navbar";
import NavbarHome from "./pages/shared/Home Components/Navbar";
//admin
const AdminLogin = lazy(() => import("./pages/admin/Login"));
const AdminInbox = lazy(() => import("./pages/admin/Inbox"));
const AdminAccount = lazy(() => import("./pages/admin/Account"));
const ClientManagement = lazy(() => import("./pages/admin/ClientManagement"));
const PlanManagement = lazy(() => import("./pages/admin/PlanManagement"));
const AdminBillManagement = lazy(() => import("./pages/admin/BillManagement"));
const CancelledInvoice = lazy(() => import("./pages/admin/CancelledInvoice"));
const AdminManagement = lazy(() => import("./pages/admin/AdminManagement"));
//client
const ClientLogin = lazy(() => import("./pages/client/Login"));

const ClientAccount = lazy(() => import("./pages/client/Account"));
const ClientDashboard = lazy(() => import("./pages/client/Dashboard"));
const StockManagement = lazy(() => import("./pages/client/StockManagement"));
const SalesManagement = lazy(() => import("./pages/client/SalesManagement"));
const EmployeeManagement = lazy(() =>
  import("./pages/client/EmployeeManagement")
);
const AttendanceManagement = lazy(() =>
  import("./pages/client/Attendance/AttendanceManagement")
);
const Expenses = lazy(() => import("./pages/client/Expenses"));
const SalesReport = lazy(() => import("./pages/client/SalesReport"));
const GSTReport = lazy(() => import("./pages/client/GSTReport"));
const MyStore = lazy(() => import("./pages/client/MyStore"));
const PaymentHistory = lazy(() => import("./pages/client/PaymentHistory"));
const MyPlan = lazy(() => import("./pages/client/MyPlan"));
const Support = lazy(() => import("./pages/client/Support"));
const SendNotification = lazy(() => import("./pages/client/SendNotification"));

//employee
const EmployeeNotification = lazy(() =>
  import("./pages/employee/EmployeeNotification")
);
const EmployeeAccount = lazy(() => import("./pages/employee/Account"));

//shared
const PrivacyPolicy = lazy(() => import("./pages/shared/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("./pages/shared/RefundPolicy"));
const ShippingPolicy = lazy(() => import("./pages/shared/ShippingPolicy"));
const Pricing = lazy(() => import("./pages/shared/Pricing"));
const ClientRegistration = lazy(() => import("./pages/shared/Registration"));
const Home = lazy(() => import("./pages/shared/Home"));
const AboutUs = lazy(() => import("./pages/shared/AboutUs"));
const ContactUs = lazy(() => import("./pages/shared/ContactUs"));
const FAQ = lazy(() => import("./pages/shared/FAQ"));
const PasswordReset = lazy(() => import("./pages/shared/ResetPassword"));
const TermsAndConditions = lazy(() =>
  import("./pages/shared/TermsAndConditions")
);
const NotFound = lazy(() => import("./pages/shared/NotFound"));

const App = (props) => {
  const {
    checkAuth,
    fetchPlan,
    fetchDecoded,
    fetchProfile,
    auth,
    profile,
    expired,
    fetchProducts,
    fetchEmployeeNotification,
  } = props;

  useEffect(() => {
    checkAuth();
    fetchPlan();
    if (auth) {
      fetchDecoded();
      fetchProfile();
      if (auth > 1) {
        fetchProducts();
        if (auth === 3) fetchEmployeeNotification();
      }
    }
  }, [auth]);

  if (auth === 1) {
    //admin
    return (
      <Navbar>
        <Suspense
          fallback={
            <div
              style={{
                marginTop: 400,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Switch>
            <Route path="/" exact component={AdminInbox} />
            <Route
              path="/admin/client-management"
              exact
              component={ClientManagement}
            />
            <Route
              path="/admin/plan-management"
              exact
              component={PlanManagement}
            />
            <Route
              path="/admin/bill-management"
              exact
              component={AdminBillManagement}
            />
            <Route
              path="/admin/cancelled-invoice"
              exact
              component={CancelledInvoice}
            />
            <Route
              path="/admin/admin-management"
              exact
              component={AdminManagement}
            />
            {/* <Route path="/" exact component={NewBillChallanAdmin} />
            <Route path="/admin/edit-bill-challan" exact component={EditBillChallanAdmin} />
            <Route path="/admin/contractor-bill-number" exact component={ContractorBillNumbers} />
            <Route path="/account-settings" exact component={AccountAdmin} />
            <Route path="/admin/director" exact component={Director} />
            <Route path="/admin/bill-management" exact component={BillManagement} />
            <Route path="/admin/directorate" exact component={Directorate} />
            <Route path="/admin/administrator" exact component={Administrator} />
            <Route path="/admin/routes" exact component={Routes} />
            <Route path="/admin/route-rates" exact component={RouteRates} />
            <Route path="/admin/godown" exact component={Godown} />
            <Route path="/admin/contractor" exact component={Contractor} /> */}
            <Route path="/account" exact component={AdminAccount} />
            <Route path="" exact component={NotFound} />
          </Switch>
        </Suspense>
      </Navbar>
    );
  } else if (auth > 1) {
    //director
    if (Object.keys(profile).length) {
      if (expired) {
        return (
          <Navbar>
            <Suspense
              fallback={
                <div
                  style={{
                    marginTop: 400,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              }
            >
              <Switch>
                <Route path="*" exact component={MyPlan} />
              </Switch>
            </Suspense>
          </Navbar>
        );
      }
      return (
        <Navbar>
          <Suspense
            fallback={
              <div
                style={{
                  marginTop: 400,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            }
          >
            <Switch>
              <Route path="/" exact component={ClientDashboard} />
              <Route
                path="/stock-management"
                exact
                component={
                  auth === 2
                    ? StockManagement
                    : profile.role.includes("X") || profile.role.includes("B")
                    ? StockManagement
                    : NotFound
                }
              />
              <Route
                path="/sales-management"
                exact
                component={
                  auth === 2
                    ? SalesManagement
                    : profile.role.includes("X") || profile.role.includes("A")
                    ? SalesManagement
                    : NotFound
                }
              />
              <Route
                path="/sales-management/:mode/:query"
                exact
                component={SalesManagement}
              />
              <Route
                path="/sales-management/:mode/:from/:to"
                exact
                component={SalesManagement}
              />

              <Route
                path="/employee-management"
                exact
                component={
                  auth === 2 && profile.plan === 2
                    ? EmployeeManagement
                    : NotFound
                }
              />
              <Route
                path="/attendance-management"
                exact
                component={
                  auth === 2 && profile.plan === 2
                    ? AttendanceManagement
                    : NotFound
                }
              />
              <Route
                path="/my-store"
                exact
                component={auth === 2 ? MyStore : NotFound}
              />
              <Route
                path="/expenses"
                exact
                component={auth === 2 ? Expenses : NotFound}
              />
              <Route
                path="/expenses/:from/:to"
                exact
                component={auth === 2 ? Expenses : NotFound}
              />
              <Route
                path="/sales-report"
                exact
                component={auth === 2 ? SalesReport : NotFound}
              />
              <Route
                path="/sales-report/:from/:to"
                exact
                component={auth === 2 ? SalesReport : NotFound}
              />
              <Route
                path="/gst-report"
                exact
                component={auth === 2 ? GSTReport : NotFound}
              />
              <Route
                path="/gst-report/:from/:to"
                exact
                component={auth === 2 ? GSTReport : NotFound}
              />
              <Route
                path="/support"
                exact
                component={auth === 2 ? Support : NotFound}
              />
              <Route
                path="/send-notification"
                exact
                component={auth === 2 ? SendNotification : NotFound}
              />
              <Route
                path="/employee-notification"
                exact
                component={auth === 3 ? EmployeeNotification : NotFound}
              />
              <Route
                path="/payment-history"
                exact
                component={auth === 2 ? PaymentHistory : NotFound}
              />
              <Route
                path="/my-plan"
                exact
                component={auth === 2 ? MyPlan : NotFound}
              />
              <Route
                path="/account"
                exact
                component={auth === 2 ? ClientAccount : EmployeeAccount}
              />
              <Route path="" exact component={NotFound} />
            </Switch>
          </Suspense>
        </Navbar>
      );
    } else {
      return (
        <div
          style={{
            marginTop: 300,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
  } else if (auth === 0) {
    return (
      <NavbarHome>
        <Suspense
          fallback={
            <div
              style={{
                marginTop: 400,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/login" exact component={ClientLogin} />
            <Route path="/about-us" exact component={AboutUs} />
            <Route path="/contact-us" exact component={ContactUs} />
            <Route path="/faq" exact component={FAQ} />
            <Route path="/pricing" exact component={Pricing} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/refund-policy" exact component={RefundPolicy} />
            <Route path="/shipping-policy" exact component={ShippingPolicy} />
            <Route path="/registration" exact component={ClientRegistration} />
            <Route path="/reset-password" exact component={PasswordReset} />
            <Route path="/admin-login" exact component={AdminLogin} />
            <Route
              path="/terms-and-conditions"
              exact
              component={TermsAndConditions}
            />
            <Route path="" exact component={NotFound} />
          </Switch>
        </Suspense>
      </NavbarHome>
    );
  } else {
    return (
      <div
        style={{
          marginTop: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return { auth: state.auth, profile: state.profile, expired: state.expired };
};
export default connect(mapStateToProps, {
  checkAuth,
  fetchPlan,
  fetchDecoded,
  fetchProfile,
  fetchProducts,
  fetchEmployeeNotification,
})(App);
