import {
  Container,
  Typography,
  Link as ALink,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import {
  AddBox,
  ExitToApp,
  HeadsetMic,
  Home,
  Info,
  LiveHelp,
  LocalOffer,
  Menu,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
const Navbar = ({ children }) => {
  const [currentFocus, setCurrentFocus] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation().pathname;
  const MENUS = [
    {
      link: "/",
      title: "HOME",
      icon: <Home fontSize="large" />,
    },
    {
      link: "/about-us",
      title: "ABOUT US",
      icon: <Info fontSize="large" />,
    },
    {
      link: "/contact-us",
      title: "CONTACT US",
      icon: <HeadsetMic fontSize="large" />,
    },
    {
      link: "/faq",
      title: "FAQ",
      icon: <LiveHelp fontSize="large" />,
    },
    {
      link: "/pricing",
      title: "PRICING",
      icon: <LocalOffer fontSize="large" />,
    },
    {
      link: "/login",
      title: "LOGIN",
      icon: <ExitToApp fontSize="large" />,
    },
    {
      link: "/registration",
      title: "REGISTER",
      icon: <AddBox fontSize="large" />,
    },
  ];
  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
    };
  }, []);

  const renderDrawer = () => {
    return (
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        <List
          style={{
            background: "#823cd7",
            height: "100%",
            width: 220,
          }}
        >
          {MENUS.map((item, index) => (
            <ListItem
              key={index}
              style={{
                backgroundColor: location === item.link && "#fff",
              }}
              button
              dense
              onClick={() => setOpenDrawer(false)}
              component={Link}
              to={item.link}
            >
              <ListItemText
                primary={
                  <Typography
                    style={{
                      fontWeight: 500,
                      color: location === item.link ? "#222" : "#fff",
                    }}
                  >
                    {item.title}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    );
  };
  return (
    <div>
      <Toolbar
        variant="dense"
        style={{
          borderBottom: "1px solid #ddd",
          paddingBottom: 8,
          paddingTop: 8,
          display: screenWidth > 960 && "none",
        }}
      >
        <IconButton
          size="medium"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            setOpenDrawer(true);
          }}
        >
          <Menu fontSize="large" />
        </IconButton>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 10,
          }}
        >
          <img alt="Logo" src="/logo512.png" style={{ height: 50 }} />{" "}
          <Typography
            style={{
              color: "#000",
              fontWeight: 600,
              fontSize: 20,
              marginLeft: 5,
            }}
          >
            STOCKUS
          </Typography>
        </div>
      </Toolbar>
      <Container
        maxWidth="md"
        style={{
          marginTop: 20,
          display: screenWidth < 960 && "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {MENUS.map((item, index) => {
            return (
              <div key={index} style={{ flex: 1, textAlign: "center" }}>
                <ALink
                  onMouseEnter={() => setCurrentFocus(item.title)}
                  onMouseLeave={() => setCurrentFocus(null)}
                  to={item.link}
                  component={Link}
                  color={
                    currentFocus === item.title ? "primary" : "textPrimary"
                  }
                  underline="none"
                  style={{ width: 95, height: 95 }}
                >
                  <div>
                    {item.icon}
                    <Typography
                      align="center"
                      style={{ fontSize: 11, fontWeight: 500 }}
                    >
                      {item.title}
                    </Typography>
                  </div>
                </ALink>
              </div>
            );
          })}
        </div>
      </Container>
      {children}
      <Footer />
      {renderDrawer()}
    </div>
  );
};

export default Navbar;
