import jwt from "jsonwebtoken";
import moment from "moment";
import api from "../api/api";
import publickey from "../api/publickey";

export const checkAuth = () => async (dispatch) => {
  try {
    if (localStorage.getItem("admin-auth")) {
      jwt.verify(localStorage.getItem("admin-auth"), publickey, async (err) => {
        if (!err) {
          return dispatch({
            type: "AUTH_ADMIN",
          });
        } else {
          console.log(err);
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      });
    } else if (localStorage.getItem("client-auth")) {
      jwt.verify(
        localStorage.getItem("client-auth"),
        publickey,
        async (err) => {
          if (!err) {
            return dispatch({
              type: "AUTH_CLIENT",
            });
          } else {
            console.log(err);
            localStorage.clear();
            return dispatch({
              type: "AUTH_FAIL",
            });
          }
        }
      );
    } else if (localStorage.getItem("employee-auth")) {
      jwt.verify(
        localStorage.getItem("employee-auth"),
        publickey,
        async (err) => {
          if (!err) {
            return dispatch({
              type: "AUTH_EMPLOYEE",
            });
          } else {
            localStorage.clear();
            return dispatch({
              type: "AUTH_FAIL",
            });
          }
        }
      );
    } else {
      localStorage.clear();
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  } catch (error) {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
};

export const fetchDecoded = () => (dispatch) => {
  let token;
  if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else if (localStorage.getItem("client-auth")) {
    token = localStorage.getItem("client-auth");
  } else if (localStorage.getItem("employee-auth")) {
    token = localStorage.getItem("employee-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, (err, decoded) => {
    if (!err) {
      try {
        return dispatch({
          type: "FETCH_DECODED",
          payload: decoded,
        });
      } catch (error) {
        return dispatch({
          type: "CLEAR_DECODED",
        });
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const fetchProfile = () => async (dispatch) => {
  let token;
  let profile;
  if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
    profile = "admin";
  } else if (localStorage.getItem("client-auth")) {
    token = localStorage.getItem("client-auth");
    profile = "client";
  } else if (localStorage.getItem("employee-auth")) {
    token = localStorage.getItem("employee-auth");
    profile = "employee";
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(`/api/${profile}/${decoded.id}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        if (profile === "client" || profile === "employee") {
          if (moment(response.data.valid_till).isAfter(moment(new Date()))) {
            dispatch({
              type: "SET_EXPIRED",
            });
          } else {
            dispatch({
              type: "SET_NOT_EXPIRED",
            });
          }
        }
        return dispatch({
          type: "FETCH_PROFILE",
          payload: response.data.data,
        });
      } catch (error) {
        if (error.response === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
        return dispatch({
          type: "CLEAR_PROFILE",
        });
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const fetchProducts = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("client-auth")) {
    token = localStorage.getItem("client-auth");
  } else if (localStorage.getItem("employee-auth")) {
    token = localStorage.getItem("employee-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(`/api/product/${decoded.client_id}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        return dispatch({
          type: "FETCH_PRODUCTS",
          payload: response.data.data,
        });
      } catch (error) {
        if (error.response === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
        return dispatch({
          type: "CLEAR_PRODUCT",
        });
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const fetchDashboard = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("client-auth")) {
    token = localStorage.getItem("client-auth");
  } else if (localStorage.getItem("employee-auth")) {
    token = localStorage.getItem("employee-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(`/api/dashboard/${decoded.client_id}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        return dispatch({
          type: "FETCH_DASHBOARD",
          payload: response.data,
        });
      } catch (error) {
        if (error.response === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
        return dispatch({
          type: "CLEAR_DASHBOARD",
        });
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};
export const fetchEmployeeNotification = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("employee-auth")) {
    token = localStorage.getItem("employee-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(
          `/api/notification/${decoded.client_id}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        return dispatch({
          type: "FETCH_NOTIFICATION",
          payload: response.data.data,
        });
      } catch (error) {
        if (error.response === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
        return dispatch({
          type: "CLEAR_NOTIFICATION",
        });
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const fetchPlan = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/plan`);
    return dispatch({
      type: "FETCH_PLAN",
      payload: response.data.data,
    });
  } catch (error) {
    return dispatch({
      type: "CLEAR_PLAN",
    });
  }
};

export const logout = () => async (dispatch) => {
  window.location.href = "/";
  localStorage.clear();
  return dispatch({
    type: "AUTH_FAIL",
  });
};
