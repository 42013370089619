import { combineReducers } from "redux";
import authReducer from "./authReducer";
import decodedReducer from "./decodedReducer";
import profileReducer from "./profileReducer";
import expiredReducer from "./expiredReducer";
import planReducer from "./planReducer";
import dashboardReducer from "./dashboardReducer";
import productReducer from "./productReducer";
import notificationReducer from "./notificationReducer";

export default combineReducers({
  auth: authReducer,
  decoded: decodedReducer,
  profile: profileReducer,
  expired: expiredReducer,
  plan: planReducer,
  dashboard: dashboardReducer,
  product: productReducer,
  notification: notificationReducer,
});
